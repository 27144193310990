import { createLogger, createStore } from 'vuex'
import { fetchCode, fetchFriends, fetchMedia, fetchPages } from '@/rest'
import { version } from '../../package.json'

const dbVersion = version
const today = new Date()
const date = today.getFullYear() + String(today.getMonth() + 1).padStart(2, '0') + String(today.getDate()).padStart(2, '0')
const defaultProgress = {
  solved: false,
  tries: 0,
  stage: 0,
  game: null
}

export default createStore({
  state: {
    version: dbVersion,
    date: date,
    pages: [],
    friends: [],
    media: [],
    progress: {},
    friend: null,
    nonce: undefined,
    code: undefined,
    disabled: 0
  },
  getters: {
    getPageContent: (state, getters) => (slug) => {
      const page = getters.getPage(slug)
      if (page && page.content) {
        return page.content
      }
      return ''
    },
    getPageImage: (state, getters) => (slug, size = 'large') => {
      const page = getters.getPage(slug)
      if (page && page.media) {
        const media = getters.getMedia(page.media)
        if (media && media.size && media.size[size]) {
          return media.size[size]
        }
      }
      return ''
    },
    getImage: (state, getters) => (id, size = 'large') => {
      const media = getters.getMedia(id)
      if (media && media.size && media.size[size]) {
        return media.size[size]
      }
      return ''
    },
    getMedia: (state) => (id) => {
      for (const i in state.media) {
        if (state.media[i].id === id) {
          return state.media[i]
        }
      }
      return undefined
    },
    getPage: (state) => (slug) => {
      for (const i in state.pages) {
        if (state.pages[i].slug === slug) {
          return state.pages[i]
        }
      }
      return undefined
    },
    getFriend: (state) => (slug = null) => {
      if (slug === null) {
        slug = state.friend
      }
      for (const i in state.friends) {
        if (state.friends[i].slug === slug) {
          return state.friends[i]
        }
      }
      return undefined
    },
    getFriends (state, getters) {
      return state.friends.map(friend => {
        return Object.assign({}, friend, getters.getProgress(friend.slug))
      })
    },
    getProgress: (state) => (slug = null) => {
      if (slug === null) {
        slug = state.friend
      }
      if (slug && state.progress[slug]) {
        return state.progress[slug]
      }
      return defaultProgress
    },
    getGame (state, getters) {
      const progress = getters.getProgress()
      const friend = getters.getFriend()
      if (friend && friend.games && progress) {
        return friend.games[progress.game]
      }
      return undefined
    },
    getScore (state, getters) {
      let score = 0
      for (const i in state.friends) {
        const friend = state.friends[i]
        const progress = getters.getProgress(friend.slug)
        if (progress.solved) {
          score++
        }
      }
      return score
    },
    isDisabled (state) {
      return state.disabled !== 0
    }
  },
  mutations: {
    initialiseState (state, payload) {
      if (payload) {
        const store = JSON.parse(payload)
        // Replace the state object with the stored item
        if (store.version === dbVersion) {
          if (store.date === date) {
            console.log('State Restored')
            this.replaceState(
              Object.assign(state, store)
            )
          } else {
            console.warn('Date mismatch')
          }
        } else {
          console.warn('Version mismatch')
        }
      }
    },
    populatePages (state, payload) {
      state.pages = payload
    },
    populateFriends (state, payload) {
      state.friends = payload
    },
    populateMedia (state, payload) {
      state.media = payload
    },
    setFriend (state, payload) {
      state.friend = payload
    },
    setProgress (state, payload) {
      if (state.friend) {
        state.progress[state.friend] = payload
      }
    },
    setCode (state, payload) {
      state.code = payload
    },
    setNonce (state) {
      if (!state.nonce) {
        const crypto = require('crypto')
        state.nonce = crypto.randomBytes(16).toString('base64')
      }
    },
    setDisabled (state, payload) {
      state.disabled = payload
    },
    resetGame (state) {
      state.progress = {}
      state.friend = null
      state.code = undefined
      const crypto = require('crypto')
      state.nonce = crypto.randomBytes(16).toString('base64')
    }
  },
  actions: {
    getPages (context) {
      fetchPages().then(data => {
        context.commit('populatePages', data.map(item => {
          return {
            slug: item.slug,
            title: item.title.rendered,
            content: item.content.rendered.trim('\n'),
            media: item.featured_media
          }
        }))
      })
    },
    getFriends (context) {
      fetchFriends().then(data => {
        context.commit('populateFriends', data)
      })
    },
    getMedia (context) {
      fetchMedia().then(data => {
        context.commit('populateMedia', data)
      })
    },
    updateProgress (context, payload) {
      const progress = context.getters.getProgress()
      context.commit('setProgress', Object.assign({}, progress, payload))
    },
    getCode (context) {
      fetchCode(context.state.nonce).then(data => {
        context.commit('setCode', data)
      })
    },
    disable (context, payload) {
      let timer = Number.isInteger(payload) ? payload : context.state.disabled
      context.commit('setDisabled', timer)
      if (timer) {
        const interval = setInterval(() => {
          if (--timer < 1) {
            clearInterval(interval)
            context.commit('setDisabled', 0)
          } else {
            context.commit('setDisabled', timer)
          }
        }, 1000)
      }
    }
  },
  modules: {},
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
