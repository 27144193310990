<template>
  <div :class="getClass">
    <div v-if="type !== 'full'" id="upper" class="container">
      <img v-if="getImage" :src="getImage" alt=""/>
    </div>
    <div id="lower" class="gradbg">
      <div class="container">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
    image: [String, Number]
  },
  computed: {
    getImage () {
      if (typeof this.image === 'string') {
        return this.image
      }
      const image = this.$store.getters.getMedia(this.image)
      if (image && image.size && image.size.large) {
        return image.size.large
      }
      return ''
    },
    getClass () {
      let staticClass = 'page'
      if (this.type) {
        staticClass += ' ' + this.type
      }
      return staticClass
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  #upper {
    color: black;
    max-height: 40vh;
    img {
      width: 75%;
      height: auto;
    }
  }
  &.full {
    #lower {
      min-height: 100vh;
      .container {
        min-height: calc(100vh - 2rem);
        display: flex;
        flex-flow: column;
        justify-content: space-around;
      }
    }
  }
  &.big {
    #upper {
      img {
        width: auto;
        max-height: 100%;
      }
    }
  }
}
</style>
