<template>
  <Page v-if="$store.getters.getFriend()" :image="getPageImage" :type="getPageType">
    <h1>{{ $store.getters.getFriend().name }}</h1>
    <div v-if="$store.getters.getProgress().stage === 0">
      <p>
        {{ $store.getters.getFriend().content }}
      </p>
      <button @click="next()" class="center">
        Jatka
      </button>
    </div>
    <div v-if="getGameType === 'question'">
      <!-- Question -->
      <div v-if="$store.getters.getProgress().stage === 1">
        <Question :text="$store.getters.getGame.text"
                  :options="$store.getters.getGame.options" :answer="$store.getters.getGame.answer" @result="answer"/>
      </div>
      <div v-else-if="$store.getters.getProgress().stage === 2" class="last-element">
        <p v-if="$store.getters.getProgress().solved">
          {{ $store.getters.getGame.correct }}
        </p>
        <p v-else>
          {{ $store.getters.getGame.incorrect }}
        </p>
        <button @click="next()" class="center" :disabled="$store.getters.isDisabled">
          Jatka <span v-if="$store.getters.isDisabled">({{ $store.state.disabled }})</span>
        </button>
      </div>
    </div>
    <template v-else-if="getGameType === 'wheel' && $store.getters.getProgress().stage > 0">
      <!-- Wheel of Fortune -->
      <div class="wheel-container" v-touch:swipe="spin">
        <WheelOfFortune ref="wheel" :images="getImages" @result="wheelResult" />
        <div v-if="$store.getters.getProgress().stage === 2">
          <p v-if="$store.getters.getProgress().solved">
            {{ $store.getters.getGame.correct }}
          </p>
          <p v-else>
            {{ $store.getters.getGame.incorrect }}
          </p>
        </div>
      </div>
      <div class="last-element">
        <button v-if="$store.getters.getProgress().solved" @click="next()">
          Jatka
        </button>
        <button v-else @click="spin()">
          Pyöritä
        </button>
      </div>
    </template>
    <template v-else-if="getGameType === 'slots' && $store.getters.getProgress().stage > 0">
      <!-- Slots -->
      <SlotMachine ref="slots" :hahmo="$store.getters.getGame.friend" @result="slotResult"/>
      <div v-if="$store.getters.getProgress().stage === 2">
        <p v-if="$store.getters.getProgress().solved">
          {{ $store.getters.getGame.correct }}
        </p>
        <p v-else>
          {{ $store.getters.getGame.incorrect }}
        </p>
      </div>
      <div class="last-element">
        <button v-if="$store.getters.getProgress().solved" @click="next()">
          Jatka
        </button>
        <button v-else @click="pull()">
          Pelaa
        </button>
      </div>
    </template>
  </Page>
</template>
<script>
import Page from '@/components/Page'
import Question from '@/components/Question'
import WheelOfFortune from '@/components/WheelOfFortune'
import SlotMachine from '@/components/SlotMachine'
import star from '@/assets/wheel_star.svg'
import icecream from '@/assets/wheel_icecream.svg'
import clover from '@/assets/wheel_clover.svg'

export default {
  components: {
    SlotMachine,
    WheelOfFortune,
    Question,
    Page
  },
  methods: {
    next () {
      const progress = this.$store.getters.getProgress()
      const newProgress = { stage: 1 }
      if (progress.stage === 0) {
        newProgress.game = progress.game === null ? Math.floor(Math.random() * this.$store.getters.getFriend().games.length) : progress.game
      }
      if (progress.solved && progress.stage > 1) {
        this.$router.push('/score')
        newProgress.stage = 3
      }
      this.$store.dispatch('updateProgress', newProgress)
    },
    answer (result) {
      const progress = this.$store.getters.getProgress()
      this.$store.dispatch('updateProgress', { stage: 2, tries: progress.tries + 1, solved: result })
      if (!result) {
        this.$store.dispatch('disable', 5)
      }
    },
    wheelResult (result) {
      this.$store.dispatch('updateProgress', {
        stage: 2,
        solved: (result === 0 || result === 4)
      })
      console.debug(result)
    },
    slotResult (result) {
      this.$store.dispatch('updateProgress', {
        stage: 2,
        solved: result
      })
    },
    spin () {
      const tries = this.$store.getters.getProgress().tries + 1
      this.$store.dispatch('updateProgress', { stage: 1, tries, solved: false })
      let result = null
      if (tries > 2 || (tries > 1 && Math.floor(Math.random() * 3) === 0)) {
        result = Math.floor(Math.random() * 2) * 4
      }
      this.$refs.wheel.spin(result)
    },
    pull () {
      const tries = this.$store.getters.getProgress().tries + 1
      this.$store.dispatch('updateProgress', { stage: 1, tries, solved: false })
      let result = null
      if (tries > 2 || (tries > 1 && Math.floor(Math.random() * 3) === 0)) {
        result = this.$store.getters.getGame.friend
        console.debug(result)
      }
      this.$refs.slots.start(result)
    }
  },
  computed: {
    getGameType () {
      const game = this.$store.getters.getGame
      if (game && game.type) {
        return game.type
      }
      return ''
    },
    getPageType () {
      const progress = this.$store.getters.getProgress()
      if (progress.stage > 0 && (this.getGameType === 'wheel' || this.getGameType === 'slots')) {
        return 'full'
      }
      return 'half'
    },
    getPageImage () {
      if (this.getGameType === 'question' && this.$store.getters.getGame.image) {
        return this.$store.getters.getGame.image
      }
      if (this.$store.getters.getFriend()) {
        return this.$store.getters.getFriend().image
      }
      return ''
    },
    getImages () {
      const game = this.$store.getters.getGame
      if (game && game.image) {
        const image = this.$store.getters.getImage(game.image, 'thumbnail')
        return [
          { url: image, size: 120 },
          star,
          icecream,
          star,
          { url: image, size: 120 },
          clover,
          icecream,
          clover
        ]
      }
      return []
    }
  },
  beforeMount () {
    this.$store.commit('setFriend', this.$route.params.id)
  }
}
</script>
<style lang="scss" scoped>
.slot-machine {
  flex-grow: 1;
}

.wheel-container {
  flex-grow: 1;
}

.last-element {
  flex-grow: 1;
}
</style>
