<template>
  <Page type="full">
    <div v-html="$store.getters.getPageContent('etsi')"/>
    <video ref="qroutput"></video>
    <button @click="scan">Scan</button>
  </Page>
</template>
<script>
import Page from '@/components/Page'
import QrScanner from 'qr-scanner'
import QrScannerWorkerPath from '!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js' // eslint-disable-line
QrScanner.WORKER_PATH = QrScannerWorkerPath
export default {
  components: {
    Page
  },
  methods: {
    scan () {
      const qrScanner = new QrScanner(this.$refs.qroutput, result => {
        const match = 'https://zoolandia.qrpeli.fi/qr/'
        if (result.substr(0, match.length) === match) {
          console.debug('Matched QR', result)
          qrScanner.stop()
          qrScanner.destroy()
          document.location = result
        } else {
          console.warn('Unrecognized qr code:', result)
        }
      })
      qrScanner.start()
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 10px auto;
}
</style>
