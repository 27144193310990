<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kreon:wght@700&family=Roboto:wght@400;700;900&display=swap');

:root {
  --color-primary: #8C006D;
  --color-secondary: #CE006D;
  --color-light: #F1F9FF;
  --color-heading: #FADE1B;
  --color-gray: #8E8E8E;
}

html, body {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
  padding: 0;
  margin: 0;
}

#app {
  overflow-x: hidden;
  font-family: 'Kreon', serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--color-light);
  color: var(--color-light);
  min-height: 100%;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: stretch;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-heading);
  font-size: 18px;
}

a {
  color: var(--color-heading);
}

.container {
  max-width: 640px;
  margin: auto;
  padding: 1rem;
}

.gradbg {
  background: linear-gradient(var(--color-secondary), var(--color-primary));
}

button {
  display: block;
  margin: auto;
  border: 0;
  border-radius: 2rem;
  color: var(--color-primary);
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  padding: 0.75rem 1rem;
  cursor: pointer;
  background: var(--color-light);
  &:disabled {
    opacity: 50%;
  }
  &.center {
    margin: 0 auto 1em auto;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 75%;
    max-width: 320px;
  }
}

img {
  max-width: 100%;

  &.logo {
    max-width: 448px;
    width: 100%;
    margin: auto;
  }
}

ol {
  text-align: left;
  margin-bottom: 2em;

  li {
    font-size: 18px;
    margin: 20px 0 0;
  }
}

figure {
  &.qr-code {
    width: 100px;
    height: 100px;
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.last-element {
  margin-bottom: 3rem;
}
</style>
